/** @deprecated - this is moved to libs */
export enum DocumentsEnum {
    AnnualAward = '/documenten/download/reglement-jaarbeloning.pdf',
    BonusMalusStepCarInsurance = '/documenten/download/InShared_Bonus-Malus_tredes_AUTO_contentpagina.pdf',
    BonusMalusStepMopedInsurance = '/documenten/download/InShared_Bonus-Malus_tredes_BROMMER_contentpagina.pdf',
    BonusMalusStepMotorInsurance = '/documenten/download/InShared_Bonus-Malus_tredes_MOTOR_contentpagina.pdf',
    BonusMalusTableCarInsurance = '/documenten/download/InShared_Bonus-Malus_tabel_Auto_schadevrij_jaar.pdf',
    BonusMalusTableMopedInsurance = '/documenten/download/InShared_Bonus-Malus_tabel_Brommer_schadevrij_jaar.pdf',
    BonusMalusTableMotorInsurance = '/documenten/download/InShared_Bonus-Malus_tabel_Motor_schadevrij_jaar.pdf',
    ConditionsAccidentInsurance = '/documenten/download/voorwaarden-ongevallenverzekering.pdf',
    ConditionsBicycleInsurance = '/documenten/download/voorwaarden-fietsverzekering.pdf',
    ConditionsCarInsurance = '/documenten/download/voorwaarden-autoverzekering.pdf',
    ConditionsCaravanInsurance = '/documenten/download/voorwaarden-caravanverzekering.pdf',
    ConditionsCollectivity = '/documenten/download/voorwaarden-collectiviteit.pdf',
    ConditionsCollectivityHEMA = '/documenten/download/voorwaarden-collectiviteit-HEMA.pdf',
    ConditionsGeneral = '/documenten/download/voorwaarden-algemeen.pdf',
    ConditionsHomeInsurance = '/documenten/download/voorwaarden-woonverzekering.pdf',
    ConditionsLegalInsurance = '/documenten/download/voorwaarden-rechtsbijstandverzekering.pdf',
    ConditionsLiabilityInsurance = '/documenten/download/voorwaarden-aansprakelijkheidsverzekering.pdf',
    ConditionsMopedInsurance = '/documenten/download/voorwaarden-brommerverzekering.pdf',
    ConditionsMotorInsurance = '/documenten/download/voorwaarden-motorverzekering.pdf',
    ConditionsPetInsurance = '/documenten/download/voorwaarden-dierenverzekering.pdf',
    ConditionsTravelInsurance = '/documenten/download/voorwaarden-reisverzekering.pdf',
    CookieOverview = '/documenten/download/cookieoverzicht.pdf',
    DeclarationPetInsurance = '/documenten/download/declaratie-dier.pdf',
    DepreciationFormHomeTravelInsurance = '/documenten/download/afschrijvingslijst.pdf',
    EuropeanAccidentReport = '/documenten/download/europeesschadeformulier.pdf',
    FraudPolicy = '/documenten/download/fraudebeleid.pdf',
    InSharedComplaintReport = '/documenten/download/klachtenrapportage.pdf',
    InSharedComplaintProtocol = '/documenten/download/klachtenprotocol.pdf',
    NoClaimYearsQA = '/documenten/download/q-and-a-verbond.pdf',
    ProductGuideRoadsideAssistance = '/documenten/download/productwijzer-pechhulp.pdf',
    SampleLetterNoClaimYears = '/documenten/download/voorbeeldbrief-schadevrije-jaren.docx',
    StatementOfApproval = '/documenten/download/akkoordverklaring.pdf',
    TelephonyMenu = '/documenten/download/keuzemenu-telefonie.pdf',
    Waiver = '/documenten/download/afstandsverklaring.pdf',
    WitnessStatement = '/documenten/download/getuigenverklaring.pdf',
    PersonalInjuryBrochure = '/documenten/download/letselschadefolder.pdf',
    ConditionsActionHome = '/documenten/download/actievoorwaarden-2015-001.pdf',
    ConditionsActionTravel = '/documenten/download/actievoorwaarden-2016-001.pdf',
    ConditionsActionPet = '/documenten/download/actievoorwaarden-dierenverzekering-2024.pdf',
    ConditionsActionBicycle = '/documenten/download/actievoorwaarden-2015-001.pdf',
    ReviewRules = '/documenten/download/de-6-review-spelregels-van-inshared.pdf',
    ConditionsNewActionBicycle = '/documenten/download/actievoorwaarden-fietsverzekering-5-korting.pdf',
    ConditionsNewActionPet = '/documenten/download/actievoorwaarden-dierenverzekering-5-korting.pdf',
    FifteenthAnniversary = '/documenten/download/actievoorwaarden-inshared-15-jaar.pdf',
    EuropeSixMonthFree = '/documenten/download/actievoorwaarden-autoverzekering-met-module-pechhulp-europa-6-maanden-gratis-2024AH.pdf',
}
